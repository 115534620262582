
import { Vue, Component } from 'vue-property-decorator';
import { User } from '../models';
import { goalsService } from '../services';

@Component({ components: {} })
export default class Dashboard extends Vue {
  config = {};

  get user(): User {
    return this.$store.getters['user/user'];
  }

  get viewModeIcon(): string {
    return this.$store.getters['goals/viewModeIcon'];
  }

  toggleView() {
    this.$store.dispatch('goals/toggleViewMode');
  }

  created() {
    // Event listeners
    // this.$bus.$off('goals-clone').$on('goals-clone', this.onGoalsClone);
    // this.$bus.$off('goals-export').$on('goals-export', this.onGoalsExport);
    // this.$bus.$off('goals-delete').$on('goals-delete', this.onGoalsDelete);
  }

  mounted() {
    this.$bus.$emit('title-change', 'Goals', '/goals');
    document.title = 'AgileMate Goals';
    //this.$analytics.setCurrentScreen(document.title);
    this.$analytics.logEvent('goals-dashboard');
    this.$bind('config', goalsService.configRef);
  }

  /*
  // Event handlers
  async onGoalsClone(goals: Goals) {
    try {
      await goalsService.cloneGoals(goals, this.user);
      this.$bus.$emit('snackbar-notify', 'OKRs successfully cloned', 'success');
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
  async onGoalsExport(goals: Goals) {
    try {
      goalsService.exportGoals(goals);
      this.$bus.$emit('snackbar-notify', 'OKRs successfully exported', 'success');
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
  async onGoalsDelete(goals: Goals) {
    try {
      await goalsService.deleteGoals(goals);
      this.$bus.$emit('snackbar-notify', 'OKRs successfully deleted', 'success');
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
*/
}
