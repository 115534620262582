import { db, firestore } from '@/firebase';
import { deepCopy, downloadJson, getId } from '@/utils';
// import { Kudo, KudosWall, kudosWallStatuses, TeamMember, User } from './models';

export const goalsService = {
  adminRef: db.collection('admin'),
  configRef: db.collection('admin').doc('goals'),
  goalsRef: db.collection('goals'),

  /*
  getNewKudosWall(userId: string): KudosWall {
    return {
      id: getId(),
      title: 'Kudos Wall',
      description: '',
      background: '',
      status: kudosWallStatuses.DRAFT,
      kudos: [],
      people: {},
      config: {},
      owner: userId,
      // createdOn: Date.now(),
    };
  },
  getNewKudo(): Kudo {
    return {
      title: '',
      description: '',
      icon: '',
      color: '',
      type: '',
      // from: null,
      // to: null,
      anonymous: false,
      email: false,
      date: Date.now(),
    };
  },

  getKudosWall(id: string) {
    return this.kudosRef.doc(id);
  },
  getKudosWalls(userId: string) {
    return this.kudosRef.where('owner', '==', userId);
  },
  getKudosWallsByStatus(userId: string, status: string) {
    return this.kudosRef.where('owner', '==', userId).where('status.name', '==', status);
  },

  createKudosWall(kudosWall: KudosWall) {
    kudosWall.createdOn = Date.now();
    // this.skillsRef.doc(matrix.id).update(matrix);
    this.kudosRef.doc(kudosWall.id).set(kudosWall, { merge: true });
  },
  updateKudosWall(kudosWall: KudosWall | firestore.UpdateData) {
    kudosWall.updatedOn = Date.now();
    this.updateKudosWallData(kudosWall.id, kudosWall);
  },
  updateKudosWallData(kudosWallId: string, kudosWallData: firestore.UpdateData) {
    this.kudosRef.doc(kudosWallId).update(kudosWallData);
  },
  deleteKudosWall(kudosWall: KudosWall) {
    this.kudosRef.doc(kudosWall.id).delete();
  },
  saveKudosWall(kudosWall: KudosWall) {
    this.createKudosWall(kudosWall);
  },
  setKudosWallStatus(kudosWall: KudosWall, status: string) {
    let kudosWallStatus = kudosWallStatuses.DRAFT;
    if (status === kudosWallStatuses.ACTIVE.name) {
      kudosWallStatus = kudosWallStatuses.ACTIVE;
    } else if (status === kudosWallStatuses.CLOSED.name) {
      kudosWallStatus = kudosWallStatuses.CLOSED;
    }
    this.kudosRef.doc(kudosWall.id).update({ status: kudosWallStatus });
  },
  exportKudosWall(kudosWall: KudosWall) {
    downloadJson(kudosWall, 'kudos-' + kudosWall.id);
  },
  async cloneKudosWall(kudosWall: KudosWall, user: User) {
    const newKudosWall: KudosWall = deepCopy(kudosWall);
    newKudosWall.id = getId();
    newKudosWall.status = kudosWallStatuses.DRAFT;
    newKudosWall.title = kudosWall.title + ' (Copy)';
    newKudosWall.people = {};
    newKudosWall.config.filterPersonId = null;
    newKudosWall.owner = user.id;
    newKudosWall.createdOn = Date.now();
    newKudosWall.updatedOn = null;
    await this.createKudosWall(newKudosWall);
    return newKudosWall;
  },

  addPerson(kudosWallId: string, person: TeamMember) {
    this.kudosRef.doc(kudosWallId).update({ [`people.${person.id}`]: person });
  },
  */
};
